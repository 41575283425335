import { Col, Row, Collapse } from "antd";

import React, { useState, useRef, useEffect } from "react";

import { UnorderedListOutlined, AppstoreOutlined } from "@ant-design/icons";
import Footer from "../../component/common/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { notiError, notiSuccess } from "../../utils/notication";
import { Loader } from "react-feather";
import NodeItem from "./node-item";
import queryString from "query-string";
import { convertDateTime } from "../../utils/formatDate";
import HeaderBuyNode from "../header-buy-node";
const { Panel } = Collapse;
const BuyNodes = () => {
  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState();
  const nodeDetailSection: any = useRef(null);

  const [loadingVerify, setLoadingVerify] = useState<any>(false);

  const [nodes, setNodes]: any = useState([]);
  const [nodeActive, setNodeActive] = useState<any>(null);
  const [isHowToRef, setIsHowToRef] = useState<any>(false);

  let refCode: any = "";
  let paramUrl: any = queryString.parse(window.location.search);
  if (paramUrl.ref !== undefined && paramUrl.ref.length > 0) {
    refCode = paramUrl.ref;
  }

  let access_token: any = localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
    : "";
  let signatureMeta: any = localStorage.getItem("signature")
    ? localStorage.getItem("signature")
    : "";

  const [param, setParam]: any = useState({
    page: 1,
    size: 20,
  });

  const getListNodes = async () => {
    await BaseApi.getListNodes(param)
      .then((res) => {
        if (res.status == 200 && res.data && res.data.data) {
          let poolsData: any = res.data.data;
          setNodes(poolsData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getListNodes();
  }, []);

  let settings: any = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 9,
    autoplay: true,
    slidesToScroll: 1,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let settings2: any = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 9,
    autoplay: true,
    slidesToScroll: 1,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let settings3: any = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 9,
    autoplay: true,
    slidesToScroll: 1,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (nodes.length > 0 && nodeActive == null) {
      // let keyNode:any = localStorage.getItem('keyItem') ? localStorage.getItem('keyItem') : 0;
      setNodeActive(nodes[1]);
    }
  }, [nodes, nodeActive]);

  const handleRef = () => {
    // localStorage.setItem('activeTab', 'referrals');
    window.location.replace("/get-ref");
  };

  return (
    <>
      <div className='app-page app-page-node'>
        <HeaderBuyNode />
        <div className='container'>
          <div className='nodes-sale'>
            <Row gutter={30}>
              <Col xs={24} sm={14}>
                <div className='node-banner'>
                  <div className='banner-info'>
                    <img className='w-full' src='../images/sale-banner.png' />
                  </div>
                  <h3 className='text-darkblue-500 text-center font-20 mt-0 mb-15'>
                    HeLa Guardian Node
                  </h3>
                  <div className=' text-center weight-300 font-16'>
                    Join us in this exclusive sale, limited to Guardian Nodes.
                    Are you worthy of being a HeLa Guardian?
                  </div>
                  <div className='text-center mt-15'>
                    <button
                      onClick={handleRef}
                      className='btn-blue-light font-18 p-10-15'
                    >
                      Get your referral code
                    </button>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={10}>
                <div
                  id='node-detail'
                  className='node-detail'
                  ref={nodeDetailSection}
                >
                  <NodeItem refCode={refCode} nodeActive={nodeActive} />
                </div>
              </Col>
            </Row>

            {/* <div className='mt-30 pt-15 border-top'>
                            <div className="text-center font-25 text-darkblue-500 mb-15">Why purchase the HeLa Guardian Node?</div>
                            <Collapse bordered={false} expandIconPosition="end" className='collapse-cus'>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>What benefits do I get from buying a HeLa Guardian Node?</span></>} key="1">
                                    <p className='mt-5 mb-0'>By running a HeLa Guardian Node, you directly contribute to the ecosystem by enhancing network security and supporting its growth. HeLa node owners will receive token rewards, including airdrops during listing events, and enjoy additional benefits from future collaborations with HeLa and its partners.</p>
                                    
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>How do I get started and how can I set up my node?</span></>} key="2">
                                    <p className='mt-5 mb-0'>Follow this guide for a step-by-step guide to help you get started: </p>
                                
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>Where can I buy Node?</span></>} key="3">
                                    <p className='mt-5 mb-0'>You can buy a HeLa Guardian Node through the HeLa Labs node sales page. Check it here: <a href="https://guardian.helalabs.com/">https://guardian.helalabs.com/</a></p>
                                    
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>What unique features does the HeLa node offer compared to others?</span></>} key="4">
                                    <ul className="mt-0 ul-faq">
                                        <li>First Mover Advantage: Earn a part of HeLa's total supply.</li>
                                    
                                        <li>NFT License: Get an NFT for node ownership.</li>

                                        <li>Extra Earnings: Stake NFTs to earn tokens from partnered projects.</li>

                                        <li>One Node for All: Exclusive platform for staking future NFTs.</li>

                                        <li>Earn & Stake: Get more HeLa tokens for rewards or convert to stablecoins.</li>

                                        <li>Delegation Fees: Earn from others staking their NFTs to your node.</li>

                                        <li>Potential Airdrop Incentives: Potentially earn fees from future NFTs staked on the nodes.</li>
                                    </ul>
                                </Panel>
                                
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>Are we guaranteed rewards? </span></>} key="5">
                                    <p className='mt-5 mb-0'>There is a fixed airdrop, but other perks depend on HeLa securing more partnerships. Please refer to the node deck for detailed ROI information for each tier.</p>
                                    
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>What unique features does the HeLa node offer compared to others?</span></>} key="6">
                                    <ul className="mt-0 ul-faq">
                                        <li>Earn Rewards: Get HeLa Chain Rewards for network contribution.</li>
                                    
                                        <li>Early Access: Access new NFT launches for project tokens.</li>

                                        <li>Extra Earnings: Stake HeLa tokens for more rewards or convert to stablecoins.</li>

                                        <li>Delegation Fees: Earn from others staking their NFTs to your node.</li>

                                        <li>Airdrop Incentives: Earn fees from future NFTs staked on the nodes.</li>
                                    </ul>
                                </Panel>
                            </Collapse>

                            <div className="text-center font-25 text-darkblue-500 mb-15 mt-30">Node Sale Pricing </div>
                            <Collapse bordered={false} expandIconPosition="end" className='collapse-cus'>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>What is the initial price for a HeLa node?</span></>} key="7">
                                    <p className='mt-5 mb-0'>The initial price is 330 HLUSD.</p>
                                    
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>How many Guardian Nodes are available in each tier?</span></>} key="8">
                                    <p className='mt-5 mb-0'>There are 2,500 Guardian Nodes available in each tier.</p>
                            
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>What is the total number of Guardian Nodes available for sale?</span></>} key="9">
                                    <p className='mt-5 mb-0'>A total of 50,000 Guardian Nodes are available for sale. </p>
                                    
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>Through which network will the node sale be conducted?</span></>} key="10">
                                    <p className='mt-5 mb-0'>The sale will be conducted on HeLa network via a public sale. You will be able to use USDT/USDC/HLUSD on HeLa network to purchase the nodes. Currently, as of November 2024, we support HLUSD only. </p>
                                    
                                </Panel>

                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'> Can you buy fractions of a node?</span></>} key="11">
                                    <p className='mt-5 mb-0'>No, nodes will be sold in whole units only.</p>
                                    
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'> When can nodes be transferred after purchase?</span></>} key="12">
                                    <p className='mt-5 mb-0'>Nodes can be transferred only after all the 50,000 nodes have been fully sold.</p>
                                    
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'> What is the difference per each tier? </span></>} key="13">
                                    <p className='mt-5 mb-0'>Each tier has a different price and potential return. </p>
                                    
                                </Panel>
                                
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'> What will I receive alongside the token node reward?</span></>} key="14">
                                    <p className='mt-5 mb-0'>You will receive a total of 720 HeLa tokens per node vested over a period of 4 years. Besides HeLa tokens, node owners are on the priority list to receive perks from all partnerships and campaigns within the HeLa ecosystem.</p>
                                    
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'> What is the initial price of the Guardian Nodes?</span></>} key="15">
                                    <p className='mt-5 mb-0'>The price is 330 HLUSD, equivalent to 330 USD (given that 1 HLUSD = 1 USDT/USDC). </p>
                                    
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'> How many token rewards does each node receive?</span></>} key="16">
                                    <p className='mt-5 mb-0'>Each node receives 720 HeLa tokens rewards, vested over a period of 4 years.  </p>
                                    
                                </Panel>
                            </Collapse>

                            <div className="text-center font-25 text-darkblue-500 mb-15 mt-30">Referral</div>
                            <Collapse bordered={false} expandIconPosition="end" className='collapse-cus'>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>How and when will I receive my rebate and commission?</span></>} key="17">
                                    <p className='mt-5 mb-0'>Your rebate and commission will be processed two weeks after the sale completion. Keep in mind, commissions might require passing KYC (Know Your Customer).</p>
                                    
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>What if I want to refer to a lot of people?</span></>} key="18">
                                    <p className='mt-5 mb-0'>If you have a large community or influence, you can apply for the Partner Referral Scheme and earn a commission ranging from 5% to 10%, depending on the amount sold.</p>
                            
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-darkblue-500 font-16 weight-500'>Where do I apply for the Partner Referral Scheme?</span></>} key="19">
                                    <p className='mt-5 mb-0'>You can apply for the Partner Referral Scheme using this link: Apply here. Generally, you will need to provide you Telegram ID, Email, Proof of Community Socials.  </p>
                                    
                                </Panel>
                            </Collapse>
                        </div> */}
          </div>

          <div className='partner'>
            <div className='partner-title text-darkblue-500'>Partners</div>
            <div className='partner-list'>
              <Slider {...settings}>
                <div className='partner-item'>
                  <img src='../images/partner/AIS.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/aveai.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/beepo.jpg' />
                </div>

                <div className='partner-item'>
                  <img
                    src='../images/partner/upswap.svg'
                    title='UpSwap Finance'
                  />
                </div>

                <div className='partner-item'>
                  <img
                    src='../images/partner/stablehodl.svg'
                    title='Stablehodl'
                  />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/camelot.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/caroplay.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/corestake.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/doric.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/emmetfinance.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/eonian.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/fomoin.jpg' />
                </div>
              </Slider>

              <div className='mt-15'>
                <Slider {...settings2}>
                  <div className='partner-item'>
                    <img src='../images/partner/getblock.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/habitnetwork.png' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/intract.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/katanainu.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/lightcycle.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/metakraftai.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/metatdex.png' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/nextgemai.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/nowchain.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/oort.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/opengate.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/Orbler.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/Scriptnetwork.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/sendtoken.png' />
                  </div>
                </Slider>
              </div>
              <div className='mt-15'>
                <Slider {...settings3}>
                  <div className='partner-item'>
                    <img src='../images/partner/shieldprotocol.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/siriuspad.png' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/smartdeployer.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/starkfinance.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/SYPOOL.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/tubeprotocol.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/vitalblock.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/Volarisgames.png' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/Xworldgames.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/zns.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/zuraverse.png' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/Karat Logo (1).svg' />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {loadingVerify && (
        <>
          <Loader />
        </>
      )}
    </>
  );
};
export default BuyNodes;
