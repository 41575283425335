
import { Col,Row, Collapse, Input, Form, Alert, Modal } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {UnorderedListOutlined, AppstoreOutlined} from '@ant-design/icons'

import Footer from "../../component/common/Footer";
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { notiError, notiSuccess } from "../../utils/notication";
import { Loader } from "react-feather";
import NodeItem from "./node-item";
import queryString from 'query-string';
import HeaderBuyNode from "../header-buy-node";
import WagmiConnect from "../../component/ConnectWallet/wagmi-connect";
import WagmiConnectRef from "../../component/ConnectWallet/wagmi-connect/index-ref";

const GetRef = () => {
    const [form] = Form.useForm();
    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState();
    const nodeDetailSection:any = useRef(null);

    const [setting, setSetting] = useState<any>(null);
    const [loadingVerify , setLoadingVerify] = useState<any>(false);
    const [isSuccess , setIsSuccess] = useState<any>(false);
   
    const [refferal, setRefferal] = useState<any>(null);
    
    let refCode:any = '';
    let paramUrl:any = queryString.parse(window.location.search);
    if (paramUrl.ref !== undefined && paramUrl.ref.length > 0) {
        refCode = paramUrl.ref;
    }

    let signatureMeta:any = localStorage.getItem('signature') ? localStorage.getItem('signature') : '';

    const getRefCode = async ()=>{
        let resRef = await BaseApi.getRefCode(address);
        if(resRef.status == 200 && resRef.data.data){
            let data:any = resRef.data.data;
        
            setRefferal(data);
        }
    }
    const getSetting = async() => {
        try {
            const response = await BaseApi.getSetting();
            
            if(response.status == 200 && response.data && response.data?.data){
               setSetting(response.data?.data);
            }else{
                setSetting(null);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    
    useEffect(() => {
        getSetting();
        if(address){
            getRefCode();
        }
        
    }, [address]);

    const onFill = () => {
        form.setFieldsValue({ 
            retweet_link: refferal?.retweet_link, 
            discord_link: refferal?.discord_link,
            telegram_link: refferal?.telegram_link,
            email: refferal?.email,
        });
    };

    useEffect(() => {
        if(refferal){
            onFill();
        }
        
    }, [refferal]);

    const onFinish = async (values:any) => {

        let data:any = {
            "wallet": address,
            "message": "Welcome to HeLa Node!",
            "signature": signatureMeta ? signatureMeta : '',
            "retweetLink": values?.retweet_link,
            "discordLink": values?.discord_link,
            "telegramLink": values?.telegram_link ? values?.telegram_link : '',
            "email": values?.email ? values?.email : '',
        }

        await BaseApi.submitRequestRefCode(data).then((res:any)=>{
            if(res.status == 201){
                
                form.resetFields();
                setIsSuccess(true);
            }
            
        }).catch((error:any)=>{
            notiError('Failed!', error?.message);
        });
    };

    return (
        <>
            <div className="app-page app-page-node">
                <HeaderBuyNode />
                <div className="container">
                    <div className="nodes-sale pt-0">
                        <Row gutter={30}>
                            <Col xs={24}>
                                <p className="text-darkblue-500 font-18 mt-0">Get your Referral Code</p>
                                <div className="node-banner">
                                    <div className="max-ref">
                                        <div className="banner-info">
                                            <img src="../images/sale-banner.svg" />
                                        </div>
                                        <h3 className="text-darkblue-500 text-center weight-600 font-20 mt-0 mb-10">Become a Guardian</h3>
                                        <div className=" text-center weight-300 font-16">
                                            Join our elite network as a Guardian! Share your referral code with friends to unlock huge rewards.
                                        </div>
                                        <p className="text-center"><a className="text-darkblue-500 font-16 flex-center" href="https://x.com/Hela_Network" target="_blank"><span>HeLa on X</span>  <img src="../images/ico_link.svg" /></a></p>
                                        <p className="text-center"><a className="text-darkblue-500 font-16 flex-center" href="https://discord.com/invite/helalabs"  target="_blank"><span>HeLa Discord channel</span>  <img src="../images/ico_link.svg" /></a></p>
                                        <div className="">
                                            
                                            <Form
                                                form={form}
                                                name="basic"
                                                layout="vertical"
                                                onFinish={onFinish}
                                            >
                                                <Form.Item
                                                    className='ref-social-input mt-10'
                                                    label={<><label className="weight-500">Retweet X link: <a href={setting?.retweet_link} target="_blank">{setting?.retweet_link}</a></label></>}
                                                    name="retweet_link"
                                                    // initialValue={refferal?.retweet_link}
                                                    rules={[{ required: true, message: 'Enter link you had retweet!' }]}
                                                >
                                                    <Input
                                                        className='ref-social-input'
                                                        style={{ width: '100%' }}
                                                        placeholder="Enter link you had retweet"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className='ref-social-input mt-10'
                                                    label={<><label className="weight-500">Discord ID</label></>}
                                                    name="discord_link"
                                                    // initialValue={refferal?.discord_link}
                                                    rules={[{ required: true, message: 'Enter Discord ID' }]}
                                                >
                                                    <Input
                                                        className='ref-social-input'
                                                        style={{ width: '100%' }}
                                                        placeholder="Enter Discord ID"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className='ref-social-input mt-10'
                                                    label={<><label className="weight-500">Telegram account</label></>}
                                                    name="telegram_link"
                                                    rules={[{ required: true, message: 'Enter telegram account' }]}
                                                >
                                                    <Input
                                                        className='ref-social-input'
                                                        style={{ width: '100%' }}
                                                        placeholder="Enter Telegram account"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className='ref-social-input mt-10'
                                                    label={<><label className="weight-500">Email</label></>}
                                                    name="email"
                                                    rules={[{ required: true, message: 'Enter email' }]}
                                                >
                                                    <Input
                                                        type="email"
                                                        className='ref-social-input'
                                                        style={{ width: '100%' }}
                                                        placeholder="Enter email"
                                                    />
                                                </Form.Item>
                                                <Form.Item className="text-center">
                                                    {address ? (
                                                        <>
                                                            <button type="submit" className="btn-blue-light text-white p-10-15">Get Your Referral Code</button>
                                                        </>
                                                    ):(
                                                        <>
                                                            <WagmiConnectRef />
                                                        </>
                                                    )}
                                                    
                                                </Form.Item>

                                                
                                            </Form>
                                        
                                        </div>
                                        
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Modal footer={false} onCancel={()=> window.location.reload()} onOk={()=> window.location.reload()} className="modal-mint modal-salenode" title={<></>} open={isSuccess}>
                            <div className="text-center text-blue font-24 mt-0 border-bottom-10 mb-15">Successfully!</div>
                            
                            <div className="text-center mb-15">
                                <p className="text-green font-18">We received your application, please wait for approval</p>
                                <div className="">
                                    <img src="../images/check.svg"/>
                                </div>
                                
                            </div>
                        </Modal>

            
                    </div>

                    
                </div>
                
            </div>
            <Footer />
            {loadingVerify && (
                <>
                    <Loader />
                </>
            )}
        </>
    );
};
export default GetRef;
