import { Col, Tabs, Modal, Layout, Row, Button, Empty } from "antd";

import React, { useState, useRef, useEffect } from "react";
import type { TabsProps } from "antd";
import { RightOutlined, InfoCircleOutlined } from "@ant-design/icons";

import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";

const MyNode = (props: any) => {
  const { setTotalNode } = props;

  const { address } = useAccount();
  const [nodes, setNodes]: any = useState([]);
  const [nodeActive, setNodeActive] = useState<any>(null);
  const [isModalDetail, setIsModalDetail] = useState<any>(false);

  const [param, setParam]: any = useState({
    page: 1,
    size: 100,
  });

  const getListNodes = async () => {
    await BaseApi.getMyNodes(param, address)
      .then((res) => {
        if (res.status == 200 && res.data && res.data.data) {
          let poolsData: any = res.data.data.data;
          setTotalNode(res.data.data.total);
          setNodes(poolsData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (address) {
      getListNodes();
    }
  }, [address]);

  const handleActiveNode = (item: any) => {
    setNodeActive(item);
    setIsModalDetail(true);
  };

  return (
    <>
      <Row gutter={30}>
        {nodes.length > 0 ? (
          nodes.map((item: any, key: any) => {
            return (
              <Col xs={24} sm={6} onClick={() => handleActiveNode(item)}>
                <div className='mynode-item'>
                  <div className='item-img'>
                    {/* <img src="../../images/shield-node.svg" /> */}
                    <img
                      src={`https://metadata.helachain.com/node-images/tier-${
                        item?.node_tier_id ? item?.node_tier_id : 20
                      }.png`}
                    />
                  </div>
                  <div className='item-name'>
                    {item?.node_tier?.name} ({item?.total_node})
                  </div>
                </div>
              </Col>
            );
          })
        ) : (
          <Col xs={24}>
            <div className='text-center'>
              <Empty
                description={<span className='text-white'>No data</span>}
              />
            </div>
          </Col>
        )}
      </Row>
      <Modal
        footer={false}
        className='modal-cus'
        title={
          <>
            <span className='text-white weight-500 font-20'></span>
          </>
        }
        open={isModalDetail}
        onOk={() => {
          setIsModalDetail(false);
          setNodeActive(null);
        }}
        onCancel={() => {
          setIsModalDetail(false);
          setNodeActive(null);
        }}
      >
        <Row gutter={20}>
          <Col xs={24} sm={9}>
            <div className='text-center mt-0 mb-0 imgnode-full'>
              {/* <img className="w-full" src="../../images/shield-node.svg" /> */}
              <img
                src={`https://metadata.helachain.com/node-images/tier-${
                  nodeActive?.node_tier_id ? nodeActive?.node_tier_id : 20
                }.png`}
              />
            </div>
          </Col>
          <Col xs={24} sm={15}>
            <p className='text-white font-16 mt-10'>
              {nodeActive?.node_tier?.name}
            </p>
            <p className='text-white font-16 mt-10'>
              Quantity: <span>{nodeActive?.total_node}</span>
            </p>
            <p className='text-white font-16 mt-10'>
              Unit Price:{" "}
              <span>
                {new Intl.NumberFormat("ja-JP", {
                  maximumFractionDigits: 5,
                }).format(nodeActive?.node_tier?.price)}{" "}
                <small>(HLUSD)</small>
              </span>
            </p>
          </Col>
        </Row>

        <div className='text-center'>
          <Button
            size='large'
            onClick={() => {
              setIsModalDetail(false);
              setNodeActive(null);
            }}
            htmlType='button'
            className='btn-green '
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default MyNode;
