import {
  Col,
  message,
  Input,
  Layout,
  Row,
  Progress,
  Spin,
  Tag,
  Empty,
  Modal,
} from "antd";

import React, { useState, useRef, useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { Link } from "react-router-dom";

const HomeDefault = () => {
  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState();
  const [isModalStaking, setIsModalStaking] = useState<any>(false);

  const handleShowStakeing = () => {
    setIsModalStaking(true);
  };

  const handleRef = () => {
    window.location.replace("/buy-guardian-node");
  };

  let settings: any = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 9,
    autoplay: true,
    slidesToScroll: 1,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let settings2: any = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 9,
    autoplay: true,
    slidesToScroll: 1,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let settings3: any = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 9,
    autoplay: true,
    slidesToScroll: 1,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className='app-page-default'>
        <header className='header bg-trans'>
          <div className='main-header'>
            <div className='header-left'>
              <div className='box-img'>
                <Link to='/'>
                  <img
                    src='../../../images/hela-node-logo-b.svg'
                    alt=''
                    width={122}
                    height={44}
                  />
                </Link>
              </div>
            </div>
            <div className='header-center'></div>
            <div className='header-right'></div>
          </div>
        </header>
        <div className='container'>
          <div className='home-default'>
            <Row gutter={30}>
              <Col xs={24} sm={24}>
                <div className='text-center'>
                  <div className='d-desktop'>
                    <img className='img-full' src='../images/home-banner.svg' />
                  </div>
                  <div className='d-mobile'>
                    <img className='img-full' src='../images/node-mobile.svg' />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24}>
                <div className='home-des'>
                  <p className='text-black font-20 weight-600 mb-0 mt-0'>
                    HeLa Guardian Node Booster Airdrop:
                  </p>
                  <div className='text-linegradiant mt-10'>
                    Earn Massive APY with HeLa Guardian Node
                  </div>
                  <div className='d-flex-mid-mobile mt-30 gap-10'>
                    <button
                      onClick={handleShowStakeing}
                      className='btn-border-blue text-darkblue-500 font-16 weight-500 p-12-15'
                    >
                      Join our community
                    </button>
                    <button
                      onClick={() => window.location.replace("/stake")}
                      className='btn-blue-light font-16 border-radius-30 text-white p-10-15'
                    >
                      Manage Guardian Node
                    </button>
                    <button
                      onClick={handleRef}
                      className='btn-blue-light text-white border-radius-30 font-16 p-10-15'
                    >
                      Buy Guardian Node
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className='partner mb-0 '>
            <div className='partner-title text-darkblue-500'>Partners</div>
            <div className='partner-list'>
              <Slider {...settings}>
                <div className='partner-item'>
                  <img src='../images/partner/AIS.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/aveai.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/beepo.jpg' />
                </div>

                <div className='partner-item'>
                  <img
                    src='../images/partner/upswap.svg'
                    title='UpSwap Finance'
                  />
                </div>

                <div className='partner-item'>
                  <img
                    src='../images/partner/stablehodl.svg'
                    title='Stablehodl'
                  />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/camelot.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/caroplay.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/corestake.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/doric.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/emmetfinance.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/eonian.jpg' />
                </div>

                <div className='partner-item'>
                  <img src='../images/partner/fomoin.jpg' />
                </div>
              </Slider>
              <div className='mt-15'>
                <Slider {...settings2}>
                  <div className='partner-item'>
                    <img src='../images/partner/getblock.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/habitnetwork.png' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/intract.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/katanainu.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/lightcycle.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/metakraftai.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/metatdex.png' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/nextgemai.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/nowchain.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/oort.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/opengate.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/Orbler.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/Scriptnetwork.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/sendtoken.png' />
                  </div>
                </Slider>
              </div>
              <div className='mt-15'>
                <Slider {...settings3}>
                  <div className='partner-item'>
                    <img src='../images/partner/shieldprotocol.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/siriuspad.png' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/smartdeployer.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/starkfinance.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/SYPOOL.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/tubeprotocol.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/vitalblock.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/Volarisgames.png' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/Xworldgames.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/zns.jpg' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/zuraverse.png' />
                  </div>

                  <div className='partner-item'>
                    <img src='../images/partner/Karat Logo (1).svg' />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <p className='text-center mb-30 mb-0 pb-30 text-gray'>
          Copyright © 2024 HeLa
        </p>
        {/* <Footer /> */}
      </div>
      <Modal
        footer={false}
        className='modal-mint'
        title={<></>}
        open={isModalStaking}
        onOk={() => setIsModalStaking(false)}
        onCancel={() => setIsModalStaking(false)}
      >
        <div className='social-border'>
          <div className='social-item'>
            <div className=''>
              <img src='../images/x-w.svg' />
            </div>
            <div className='d-flex-left'>
              <span className='text-white'>
                <a href='https://x.com/Hela_Network' target='_blank'>
                  X Social Media
                </a>
              </span>
              <span>
                <img src='../images/check.svg' />
              </span>
            </div>
          </div>
          <div className='social-item'>
            <div className=''>
              <img src='../images/tele-x.svg' />
            </div>
            <div className='d-flex-left'>
              <span className='text-white'>
                <a href='https://t.me/hela_official' target='_blank'>
                  Telegram channel
                </a>
              </span>
              <span>
                <img src='../images/check.svg' />
              </span>
            </div>
          </div>
          <div className='social-item'>
            <div className=''>
              <img src='../images/dis-w.svg' />
            </div>
            <div className='d-flex-left'>
              <span className='text-white'>
                <a href='https://discord.com/invite/helalabs' target='_blank'>
                  Discord Channel
                </a>
              </span>
              <span>
                <img src='../images/check.svg' />
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default HomeDefault;
