import {
  Col,
  Tabs,
  Input,
  Layout,
  Row,
  Progress,
  Divider,
  Collapse,
  Table,
  Modal,
  Button,
  Select,
  Empty,
  Spin,
  Pagination,
  Tooltip,
} from "antd";

import React, { useState, useRef, useEffect } from "react";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { RightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import MintNFTAbi from "../../config/abi/MintNFTAbi.json";
import NftNodeStakingAbi from "../../config/abi/NftNodeStakingAbi.json";
import NftNodeAbi from "../../config/abi/NftNodeAbi.json";
import { BaseApi } from "../../config/api/BaseApi";
import { useAccount } from "wagmi";
import { notiError, notiSuccess } from "../../utils/notication";
import { NodeTier } from "../../constants";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { convertFromWei } from "../../utils/convertNumber";
import type { TabsProps } from "antd";
import { convertDateTime } from "../../utils/formatDate";

declare const window: Window &
  typeof globalThis & {
    trustwallet: any;
    ethereum: any;
    bitkeep: any;
    okexchain: any;
    safepalProvider: any;
  };

const StakedNft = () => {
  const { address } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState();
  const chainId: any = selectedNetworkId;

  const [isModalMint, setIsModalMint] = useState<any>(false);

  const [myNfts, setMyNfts]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  const [loadingDataMyNft, setLoadingDataMyNft]: any = useState(false);
  const [loadingUnStaking, setLoadingUnStaking]: any = useState(false);
  const [loadingKeyStaking, setLoadingKeyStaking]: any = useState(false);
  const [totalNftRow, setTotalNftRow]: any = useState(0);
  const [stakingAddress, setStakingAddress] = useState<any>("");
  const [nodeUnstake, setNodeUnstake]: any = useState(null);

  let web3: any;
  const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");

  if (connectorId === "io.metamask" || connectorId === "bsc") {
    web3 = new Web3(window.ethereum);
  } else if (connectorId === "com.trustwallet.app") {
    web3 = new Web3(window.trustwallet);
  } else if (connectorId === "com.bitget.web3") {
    web3 = new Web3(window.bitkeep.ethereum);
  } else if (connectorId === "com.okex.wallet") {
    web3 = new Web3(window.okexchain);
  } else {
    web3 = new Web3(window.ethereum);
  }

  let stakeContract: any = new web3.eth.Contract(
    NftNodeStakingAbi as unknown as AbiItem,
    stakingAddress,
  );

  const [paramNft, setParamNft]: any = useState({
    page: 1,
    size: 20,
  });

  const getMyNfts = async () => {
    setLoadingDataMyNft(true);
    await BaseApi.getMyStakingNfts(paramNft, address)
      .then(async (res) => {
        if (res.status == 200 && res.data && res.data.data) {
          setTotalNftRow(res.data?.total);

          let arrData: any = [];

          if (res.data.data && res.data.data.length > 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              let item: any = res.data.data[i];
              let totalReward: any = 0;
              let stakeTime: any = "";
              let totalLockedTime: any = 0;

              if (stakingAddress) {
                let resSc: any = await stakeContract.methods
                  .getUserLicenseRewards(address, [item?.id])
                  .call();
                if (resSc[0]) {
                  // calculate total rewards using formula Total Reward = min(864, 288 + Total Staked Duration * 576/1460) for tier 1
                  // calculate total rewards using formula Total Reward = min(864, Total Staked Duration * 864/1460) for tier 2 & above
                  const totalLockedTimeInDays =
                    Number(resSc[0]?.totalLockedTime) / 86400;

                  if (item.node_tier_id === 1) {
                    totalReward = Math.min(
                      864,
                      288 + (totalLockedTimeInDays * 576) / 1460,
                    );
                  } else {
                    totalReward = Math.min(
                      864,
                      (totalLockedTimeInDays * 864) / 1460,
                    );
                  }

                  if (resSc[0]?.stakeTime) {
                    stakeTime = Number(resSc[0]?.stakeTime);
                  }
                  if (resSc[0]?.totalLockedTime) {
                    totalLockedTime = Number(resSc[0]?.totalLockedTime);
                  }
                }
              }

              item.stakeTime = stakeTime;
              item.totalLockedTime = totalLockedTime;
              item.totalReward = totalReward;
              arrData.push(item);
            }
          }

          setMyNfts(arrData);
          setLoadingDataMyNft(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingDataMyNft(false);
      });
  };

  const getSetting = async () => {
    try {
      const response = await BaseApi.getSetting();

      if (response.status == 200 && response.data && response.data?.data) {
        setStakingAddress(response.data?.data?.staking_pool_address);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getSetting();
  }, [address]);

  useEffect(() => {
    if (address) {
      getMyNfts();
    }
  }, [address, paramNft, stakingAddress]);

  const onChangeNft_Pagi = (pageNumber: any) => {
    setParamNft({
      ...paramNft,
      page: pageNumber,
    });
  };

  const text = (
    <>
      <p className='mt-0'>
        Each HeLa Guardian NFT will receive 288 $HELA airdropped to them from
        the beginning.
      </p>
      <p className='mb-0'>
        Staking the NFT allow owners of the NFT to start accruing extra $HELA
        tokens, from their 4-year linear vesting.
      </p>
    </>
  );

  const columns = [
    {
      title: <div className='text-gray-light'>Name</div>,
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => {
        let nameNode = NodeTier[record?.node_tier_id];
        return (
          <div className='text-blue'>HeLa Guardian Node #{record?.id}</div>
        );
      },
    },
    {
      title: <div className='text-gray-light text-center'>Tier</div>,
      dataIndex: "node_tier_id",
      key: "node_tier_id",
      render: (text: any, record: any) => {
        return (
          <div className='text-white text-center'>{record?.node_tier_id}</div>
        );
      },
    },
    {
      title: <div className='text-gray-light text-center'>Staked Date</div>,
      dataIndex: "stakeTime",
      key: "stakeTime",
      render: (text: any, record: any) => {
        return (
          <div className='text-white text-center text-uppercase'>
            {record?.stakeTime
              ? convertDateTime(new Date(record?.stakeTime * 1000))
              : "TBA"}
          </div>
        );
      },
    },
    {
      title: (
        <div className='text-gray-light text-center'>
          Total Staked Durations
        </div>
      ),
      dataIndex: "totalStakedDate",
      key: "totalStakedDate",
      render: (text: any, record: any) => {
        let time: any = "";
        let timeUnit: any = "";
        if (record?.totalLockedTime < 3600) {
          time = record?.totalLockedTime / 60;
          timeUnit = "Min";
        }
        if (record?.totalLockedTime > 3600) {
          time = record?.totalLockedTime / 3600;
          timeUnit = "Hour";
        }
        if (record?.totalLockedTime > 86400) {
          time = record?.totalLockedTime / 86400;
          timeUnit = "day";
          if (time > 1) {
            timeUnit = "Days";
          }
        }

        return (
          <div className='text-white text-center'>
            ~
            {new Intl.NumberFormat("ja-JP", {
              maximumFractionDigits: 1,
            }).format(time)}{" "}
            {timeUnit}
          </div>
        );
      },
    },
    {
      title: (
        <div className='text-gray-light text-center'>
          <Tooltip placement='bottom' title={text}>
            Total Reward <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: "totalReward",
      key: "totalReward",
      render: (text: any, record: any) => {
        return (
          <div className='d-flex-mid'>
            <span className='text-blue'>
              {record?.totalReward
                ? new Intl.NumberFormat("ja-JP", {
                    maximumFractionDigits: 5,
                  }).format(record?.totalReward)
                : 0}
            </span>
            <div className='d-flex-left'>
              <small className='text-gray-light'>$HELA</small>
            </div>
          </div>
        );
      },
    },
    {
      title: <div className='text-gray-light text-center'>Status</div>,
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => {
        return (
          <div className='text-center'>
            {stakingAddress == record?.owner_address && (
              <button
                className='btn-border-blue text-blue'
                disabled={loadingKeyStaking == record?.id && loadingUnStaking}
                onClick={() => handleUnStakeing(record)}
              >
                Unstake{" "}
                {loadingKeyStaking == record?.id && loadingUnStaking && (
                  <Spin />
                )}
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const handleUnStakeing = async (record: any) => {
    setNodeUnstake(record);
    setLoadingUnStaking(true);
    setLoadingKeyStaking(record?.id);
    try {
      if (stakeContract === null || stakingAddress == "") {
        notiError("Stake contract invalid", 4);
        setLoadingUnStaking(false);
        return;
      }

      const gasPrice = await web3.eth.getGasPrice();

      let gasEstimate = await stakeContract.methods
        .unStakeGuardianNode(record?.id)
        .estimateGas({
          from: address,
          gasPrice: web3.utils.toHex(String(gasPrice)),
        });

      await stakeContract.methods
        .unStakeGuardianNode(record?.id)
        .send({
          from: address,
          gas: gasEstimate,
          gasPrice: web3.utils.toHex(String(gasPrice)),
        })
        .then(async (resStake: any) => {
          const interval = setInterval(function () {
            web3.eth.getTransactionReceipt(
              resStake?.transactionHash,
              function (err: any, rec: any) {
                if (rec) {
                  clearInterval(interval);
                  notiSuccess("Unstake Successfully!", 5);

                  setTimeout(() => {
                    setLoadingUnStaking(false);
                    setNodeUnstake(null);
                    window.location.reload();
                  }, 2000);
                }
                if (err) {
                  console.log("err", err);
                  setLoadingUnStaking(false);
                  notiError("Unstake Failed", 3);
                }
              },
            );
          }, 1000);
        })
        .catch((error: any) => {
          console.log("stake", error);
          setIsModalMint(false);
          if (error) {
            if (error.code == 4001 && error.message) {
              notiError(error.message, 5);
            } else if (error.reason) {
              notiError(error.reason, 5);
            } else {
              if (error.data && error.data.message) {
                notiError(error.data.message, 5);
              }
            }
          }
          setLoadingUnStaking(false);
        });
    } catch (error: any) {
      console.log("error", error?.message);
      setIsModalMint(false);
      notiError(error?.message || "Unknown error occurred", 4);
      setLoading(false);
    }
  };

  return (
    <>
      <div className='d-desktop'>
        {loadingDataMyNft ? (
          <div className='text-center'>
            <Spin size='large' />
          </div>
        ) : (
          <Table
            className='table-delegates'
            bordered={false}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            dataSource={myNfts}
            columns={columns}
            pagination={false}
          />
        )}
      </div>
      <div className='d-mobile'>
        {myNfts && myNfts.length > 0 ? (
          <>
            {myNfts.map((item: any, key: any) => {
              let time: any = "";
              let timeUnit: any = "";
              if (item?.totalLockedTime < 3600) {
                time = item?.totalLockedTime / 60;
                timeUnit = "Min";
              }
              if (item?.totalLockedTime > 3600) {
                time = item?.totalLockedTime / 3600;
                timeUnit = "Hour";
              }
              if (item?.totalLockedTime > 86400) {
                time = item?.totalLockedTime / 86400;
                timeUnit = "Day";
                if (time > 1) {
                  timeUnit = "Days";
                }
              }
              let name = NodeTier[item?.node_tier_id];

              return (
                <>
                  <div className='nft-item border-bottom-10' key={`aaa${key}`}>
                    <Row gutter={30} key={`aaca${key}`}>
                      <Col xs={12} sm={5}>
                        <div className='text-gray-light weight-500'>Name</div>
                        <div className='text-blue mt-10 bg-blue p-5 w-fitcontent border-5'>
                          HeLa Guardian Node #{item?.id}
                        </div>
                      </Col>
                      <Col xs={12} sm={5}>
                        <div className='text-gray-light weight-500'>Tier</div>
                        <div className='text-blue mt-10 bg-blue p-5 w-fitcontent border-5'>
                          {item?.node_tier_id}
                        </div>
                      </Col>
                      <Col xs={12} sm={5} className='mt-0-15-mb'>
                        <div className='text-gray-light weight-500'>
                          Staked Date
                        </div>
                        <div className='text-white mt-10 text-uppercase'>
                          {item?.stakeTime
                            ? convertDateTime(new Date(item?.stakeTime * 1000))
                            : "TBA"}
                        </div>
                      </Col>
                      <Col xs={12} sm={5} className='mt-0-15-mb'>
                        <div className='text-gray-light weight-500'>
                          Total Staked Duration
                        </div>
                        <div className='text-white mt-10'>
                          ~
                          {new Intl.NumberFormat("ja-JP", {
                            maximumFractionDigits: 1,
                          }).format(time)}{" "}
                          {timeUnit}
                        </div>
                      </Col>

                      <Col xs={12} sm={5} className='mt-0-15-mb'>
                        <div className='text-gray-light weight-500'>
                          <Tooltip placement='bottom' title={text}>
                            Total Reward <InfoCircleOutlined />
                          </Tooltip>
                        </div>
                        <div className='text-white mt-10'>
                          <div className='d-flex-left'>
                            <span className='text-blue'>
                              {item?.totalReward
                                ? new Intl.NumberFormat("ja-JP", {
                                    maximumFractionDigits: 5,
                                  }).format(item?.totalReward)
                                : 0}
                            </span>
                            <div className='d-flex-left'>
                              <small className='text-gray-light'>$HELA</small>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={4} className='mt-0-15-mb'>
                        {stakingAddress == item?.owner_address && (
                          <button
                            className='btn-border-blue text-blue text-blue'
                            disabled={
                              loadingKeyStaking == item?.id && loadingUnStaking
                            }
                            onClick={() => handleUnStakeing(item)}
                          >
                            Unstake{" "}
                            {loadingKeyStaking == item?.id &&
                              loadingUnStaking && <Spin />}
                          </button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <>
            <div className='text-center'>
              <Empty
                description={<span className='text-white'>No data</span>}
              />
            </div>
          </>
        )}
      </div>

      {Number(totalNftRow) > Number(paramNft?.size) && (
        <Pagination
          current={paramNft.page}
          defaultCurrent={1}
          total={totalNftRow}
          pageSize={paramNft.size}
          onChange={onChangeNft_Pagi}
          showSizeChanger={false}
          style={{ marginTop: "15px" }}
          className='pagiation-custom mt-15'
        />
      )}

      <Modal
        footer={false}
        className='modal-mint'
        title={<></>}
        open={loadingUnStaking}
      >
        <div className='text-center text-white font-20 mt-0 border-bottom-10 mb-15'>
          Unstake {NodeTier[nodeUnstake?.node_tier_id]}
        </div>

        <div className='text-center mb-15'>
          <Spin size='large' />
        </div>
      </Modal>
    </>
  );
};
export default StakedNft;
